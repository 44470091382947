<template>
	<div
		class="graphic"
		:class="{ inline: inline }"
	>
		<div
			class="loadingContainer"
			v-if="loading"
		>
			<TnSkeleton
				height="100%"
				width="100%"
				:dark="dark"
			/>
		</div>
		<div
			class="darknessOverlay"
			:class="`percentage-${graphic?.overlayDarknessPercentage || 0}`"
		/>
		<div
			class="pauseButtonContainer"
			v-if="graphic?.video?.landscapeVideoLink || graphic?.video?.squareVideoLink"
		>
			<TnButton
				:dark="dark"
				:iconOnly="autoplay ? 'pause' : 'play'"
				tertiary
				@click="playPauseVideo"
				:aria-label="autoplay ? 'Pause video' : 'Play video'"
			></TnButton>
		</div>
		<div
			v-if="!isVideo"
			class="imgContainer"
			:class="{ loading: loading }"
		>
			<NuxtImg
				v-if="props?.graphic?.image?.squareSrc"
				:class="{
					'hidden-desktop': layout === types.layout.BACKGROUND_GRAPHIC && props?.graphic?.image?.landscapeSrc,
				}"
				:src="props.graphic.image.squareSrc"
				@load="graphicLoaded"
				:alt="props?.graphic?.altText"
				ref="imageSquare"
			/>
			<NuxtImg
				v-if="
					props?.graphic?.image?.landscapeSrc &&
					(layout === types.layout.BACKGROUND_GRAPHIC || !props?.graphic?.image?.squareSrc)
				"
				:class="{ 'hidden-mobile': props?.graphic?.image?.squareSrc }"
				:src="props.graphic.image.landscapeSrc"
				@load="graphicLoaded"
				:alt="props?.graphic?.altText"
				ref="imageLandscape"
			/>
		</div>
		<div
			v-if="isVideo"
			class="videoContainer"
			:class="{ loading: loading }"
		>
			<TnVideo
				v-if="props?.graphic?.video?.squareVideoLink"
				:class="{
					'hidden-desktop': layout === types.layout.BACKGROUND_GRAPHIC && props?.graphic?.video?.landscapeVideoLink,
				}"
				ref="videoPlayer"
				:controls="false"
				loop
				muted
				:autoplay="autoplay"
				:src-desktop="props.graphic.video.squareVideoLink"
				@metadata-ready="graphicLoaded"
				:aria-label="props?.graphic?.altText"
			/>
			<TnVideo
				v-if="
					props?.graphic?.video?.landscapeVideoLink &&
					(layout === types.layout.BACKGROUND_GRAPHIC || !props?.graphic?.video?.squareVideoLink)
				"
				:class="{ 'hidden-mobile': props?.graphic?.video?.squareVideoLink }"
				ref="videoPlayer"
				:controls="false"
				loop
				muted
				:autoplay="autoplay"
				:src-desktop="props.graphic.video.landscapeVideoLink"
				@metadata-ready="graphicLoaded"
				:aria-label="props?.graphic?.altText"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
import heroBannerCarouselTypes from "~/components/platform/editorial/HeroBannerCarousel/heroBannerCarouselTypes";

const props = defineProps({
	graphic: {
		type: Object,
		required: true,
	},
	dark: {
		type: Boolean,
		default: false,
	},
	inline: {
		type: Boolean,
		default: false,
	},
	layout: {
		type: String,
		default: heroBannerCarouselTypes.layout.DARK_WITH_SPLIT_GRAPHIC,
		validator: (value: string) => {
			return Object.values(heroBannerCarouselTypes.layout).includes(value);
		},
	},
});
const loading = ref(true);
const graphicLoaded = () => {
	loading.value = false;
};
const isVideo = !props.graphic?.image;
const types = heroBannerCarouselTypes;
const autoplay = ref(true);
const playPauseVideo = () => {
	autoplay.value = !autoplay.value;
};
const imageSquare = ref(null);
const imageLandscape = ref(null);
onMounted(() => {
	if (imageSquare.value?.$el?.complete || imageLandscape.value?.$el?.complete) {
		graphicLoaded();
	}
});
</script>
<style lang="scss" scoped>
.graphic:deep() {
	.video-container {
		height: 100%;
		.video-html {
			height: 100%;
		}
	}
	img,
	video {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
}
.graphic {
	width: 100%;
	position: relative;
	height: 100%;
	.loadingContainer {
		width: 100%;
		height: 100%;
		position: absolute;
	}
	.videoContainer,
	.imgContainer {
		height: 100%;
		width: 100%;
		transition: all 250ms ease;
		opacity: 1;
		&.loading {
			opacity: 0;
		}
	}
	&.inline {
		height: auto;
		flex-grow: 1;
	}
	.pauseButtonContainer {
		position: absolute;
		top: $spacing-m;
		left: $spacing-m;
		z-index: 3;
		@include breakpoint(mobile) {
			top: $spacing-xs;
			left: $spacing-xs;
			left: auto;
			right: 0;
		}
	}
	.darknessOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		&.percentage-0 {
			background-color: rgba(0, 0, 0, 0);
		}
		&.percentage-10 {
			background-color: rgba(0, 0, 0, 0.1);
		}
		&.percentage-20 {
			background-color: rgba(0, 0, 0, 0.2);
		}
		&.percentage-30 {
			background-color: rgba(0, 0, 0, 0.3);
		}
		&.percentage-40 {
			background-color: rgba(0, 0, 0, 0.4);
		}
		&.percentage-50 {
			background-color: rgba(0, 0, 0, 0.5);
		}
		&.percentage-60 {
			background-color: rgba(0, 0, 0, 0.6);
		}
		&.percentage-70 {
			background-color: rgba(0, 0, 0, 0.7);
		}
		&.percentage-80 {
			background-color: rgba(0, 0, 0, 0.8);
		}
		&.percentage-90 {
			background-color: rgba(0, 0, 0, 0.9);
		}
	}
}
</style>
