const layout = {
	DARK_WITH_SPLIT_GRAPHIC: "Dark with split graphic",
	LIGHTBLUE_WITH_SPLIT_GRAPHIC: "Light blue with split graphic",
	BACKGROUND_GRAPHIC: "Background graphic",
};

const ctaComponentLayout = {
	REVERT_DIRECTION_ON_MOBILE: "Revert direction on mobile",
};

const layoutToClass = (value) => {
	return {
		[layout.DARK_WITH_SPLIT_GRAPHIC]: "dark-with-split-graphic",
		[layout.BACKGROUND_GRAPHIC]: "dark-with-background-graphic",
		[layout.LIGHTBLUE_WITH_SPLIT_GRAPHIC]: "lightblue-with-split-graphic",
		[ctaComponentLayout.REVERT_DIRECTION_ON_MOBILE]: "revert-direction-on-mobile",
	}[value];
};

export default { layout, layoutToClass, ctaComponentLayout };
