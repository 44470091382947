<template>
	<div class="heroBannerCarouselContainer">
		<div class="heroBannerCarousel">
			<TnCarouselNuxt
				:dark="isDarkBackground(currentSlide)"
				:darkPagination="props.dark"
				:showPagination="isCarousel"
				inlinePagination
				:showNavigation="isCarousel && showNavigation"
				:mouseDrag="isCarousel"
				:touchDrag="isCarousel"
				:infiniteScroll="isCarousel"
				@slideEnd="slideEnd"
			>
				<TnCarouselSlideNuxt
					v-for="(slide, index) in content.carouselSlides"
					:key="index"
					:index="index"
				>
					<div
						class="carouselSlideOuterContainer"
						:class="[
							{ disableFocus: currentSlideIndex !== index },
							heroBannerCarouselTypes.layoutToClass(slide.layout),
							{ fadeDark: dark },
						]"
					>
						<div
							class="carouselSlideContainer"
							:class="[{ isCarousel: isCarousel }, { initialObserver: initialObserver }]"
							:style="carouselHeight !== 0 ? { height: carouselHeight + 'px' } : null"
						>
							<!--Side background fades-->
							<div
								class="sideFadeLeft hidden-mobile"
								v-if="isBackgroundGraphic(slide)"
							/>
							<div
								class="sideFadeRight"
								v-if="isBackgroundGraphic(slide)"
							/>

							<!--Graphic if used as background-->
							<div
								class="backgroundGraphicContainer"
								v-if="isBackgroundGraphic(slide)"
							>
								<HeroBannerCarouselGraphic
									:graphic="slide.graphic"
									:dark="isDarkBackground(slide)"
									absolute
									:layout="slide.layout"
								/>
							</div>
							<div
								class="carouselSlideContent"
								ref="carouselSlideContentRef"
								:class="[
									{ onlyOneBannerElement: onlyOneBannerElement(slide) },
									{ hasEmbeddedCtaComponents: hasEmbeddedCtaComponents(slide) },
									{ hasInlineGraphic: showInlineGraphic(slide) },
									heroBannerCarouselTypes.layoutToClass(slide?.ctaComponent?.layout),
								]"
							>
								<!--Main CTA badge, title, ingress and buttons-->
								<div
									v-if="slide.cta"
									class="slideCta"
									name="slideCta"
									:class="[{ onlyOneBannerElement: onlyOneBannerElement(slide) }]"
								>
									<div
										v-if="slide.cta.title"
										class="ctaDescription"
										:class="{ isDarkModeText: isDarkModeText(slide) }"
									>
										<TnBadge
											:category="isDarkModeButtons(slide) ? 'news' : 'offer'"
											v-if="slide.cta.badgeText"
											>{{ slide.cta.badgeText }}</TnBadge
										>
										<h1 v-if="slide.cta.title">
											{{ slide.cta.title }}
										</h1>
										<p v-if="slide.cta.ingress">
											{{ slide.cta.ingress }}
										</p>
									</div>
									<div
										class="slideCtaButtons"
										:class="{ lightSecondaryOverride: isLightSecondaryOverride(slide) }"
										v-if="slide?.cta?.buttons"
									>
										<GizmoButtons
											:dark="isDarkModeButtons(slide)"
											:component="{ content: slide?.cta?.buttons }"
										></GizmoButtons>
									</div>
								</div>
								<!--Embedded Components-->
								<div
									v-if="slide.ctaComponent?.components?.filter((c) => c.embeddedComponent)?.length"
									class="slideCtaComponentContainer"
									:class="[slide?.ctaComponent?.width]"
									ref="ctaComponentsRef"
									name="ctaComponents"
								>
									<div
										v-for="(comp, cIndex) in slide.ctaComponent.components"
										:key="cIndex"
										class="slideCtaComponent"
										:class="{ onlyCtaComponent: !slide.cta }"
									>
										<TComponent
											:dark="isDarkThemeComponent(slide)"
											:component="comp.embeddedComponent"
											v-if="comp.embeddedComponent"
										></TComponent>
									</div>
								</div>
								<!--Graphic inline (split mode)-->
								<div
									class="inlineGraphicContainer"
									v-if="showInlineGraphic(slide)"
								>
									<HeroBannerCarouselGraphic
										:graphic="slide.graphic"
										:dark="isDarkModeText(slide)"
										inline
										:layout="slide.layout"
									/>
								</div>
							</div>
						</div>
					</div>
				</TnCarouselSlideNuxt>
			</TnCarouselNuxt>
		</div>
	</div>
</template>
<script setup lang="ts">
import heroBannerCarouselTypes from "~/components/platform/editorial/HeroBannerCarousel/heroBannerCarouselTypes";

const ENABLE_FOCUS_CLASSES = ["carousel__slide--active"];
const DISABLE_FOCUS_CLASSES = ["carousel__slide--clone", "disableFocus"];

const props = defineProps({
	component: {
		type: Object,
		default: () => ({}),
	},
	dark: {
		type: Boolean,
		default: false,
	},
});

const content = computed(() => props.component?.content);
const isCarousel = computed(() => content.value?.carouselSlides?.length > 1);

const hasEmbeddedCtaComponents = (slide) => {
	return slide.ctaComponent?.components?.filter((c) => c.embeddedComponent)?.length;
};

const isBackgroundGraphic = (slide) => {
	return slide.layout === heroBannerCarouselTypes.layout.BACKGROUND_GRAPHIC && slide.graphic;
};
const showInlineGraphic = (slide) => {
	return (!slide.cta || !hasEmbeddedCtaComponents(slide)) && slide.graphic && !isBackgroundGraphic(slide);
};
const isDarkBackground = (slide) => {
	return (
		slide?.layout === heroBannerCarouselTypes.layout.DARK_WITH_SPLIT_GRAPHIC ||
		slide?.layout === heroBannerCarouselTypes.layout.BACKGROUND_GRAPHIC
	);
};
const isDarkModeText = (slide) => {
	return slide?.layout !== heroBannerCarouselTypes.layout.LIGHTBLUE_WITH_SPLIT_GRAPHIC;
};
const isDarkModeButtons = (slide) => {
	if (
		(props.dark && slide.layout === heroBannerCarouselTypes.layout.BACKGROUND_GRAPHIC) ||
		slide.layout === heroBannerCarouselTypes.layout.DARK_WITH_SPLIT_GRAPHIC
	) {
		return true;
	}
	return false;
};

const isDarkThemeComponent = (slide) => {
	if (slide.ctaComponent.theme === "light") {
		return false;
	}
	return slide.ctaComponent.theme === "dark" || isDarkModeText(slide);
};

const isLightSecondaryOverride = (slide) => {
	return !isDarkModeButtons(slide) && slide.layout === heroBannerCarouselTypes.layout.BACKGROUND_GRAPHIC;
};

const onlyOneBannerElement = (slide) => {
	return (
		(slide.cta && !hasEmbeddedCtaComponents(slide) && isBackgroundGraphic(slide)) ||
		(!slide.cta && hasEmbeddedCtaComponents(slide) && isBackgroundGraphic(slide))
	);
};

const currentSlide = computed(() => {
	return content.value.carouselSlides[currentSlideIndex.value];
});
const currentSlideIndex = ref(0);
const slideEnd = (value) => {
	currentSlideIndex.value = value.currentSlideIndex;
	nextTick(() => {
		toggleFocusForClassLists(DISABLE_FOCUS_CLASSES, ENABLE_FOCUS_CLASSES);
	});
};

const showNavigation = ref(false);
const toggleFocusForClassLists = (disableClasses, enableClasses) => {
	const disableSelector = disableClasses.map((cls) => `.${cls}`).join(", ");
	const enableSelector = enableClasses.map((cls) => `.${cls}`).join(", ");

	if (disableSelector) {
		const elementsToDisable = document.querySelectorAll(disableSelector);
		elementsToDisable.forEach((element) => {
			element.setAttribute("tabindex", "-1");
			const focusableElements = element.querySelectorAll("input, button, select, textarea, a");
			focusableElements.forEach((child) => {
				child.setAttribute("tabindex", "-1");
			});
		});
	}

	if (enableSelector) {
		const elementsToEnable = document.querySelectorAll(enableSelector);
		elementsToEnable.forEach((element) => {
			element.removeAttribute("tabindex");
			const focusableElements = element.querySelectorAll("input, button, select, textarea, a");
			focusableElements.forEach((child) => {
				child.removeAttribute("tabindex");
			});
		});
	}
};
const resizeObserver = ref(null);
const ctaComponentsRef = ref<HTMLElement[]>([]);
const carouselSlideContentRef = ref<HTMLElement[]>([]);
const carouselHeight = ref(0);
const mobileWidth = 768;
const initialObserver = ref(true);
const observerHeight = () => {
	if (resizeObserver.value) {
		resizeObserver.value.disconnect();
	}
	resizeObserver.value = new ResizeObserver((elements: ResizeObserverEntry[]) => {
		elements.forEach((element) => {
			const parent = element.target.parentElement;
			let newHeight = 0;
			if (window.innerWidth < mobileWidth) {
				Array.from(parent.children).forEach((child) => {
					newHeight += child.clientHeight;
				});
			} else {
				newHeight = parent.children.namedItem("ctaComponents")?.clientHeight || 0;
			}
			if (newHeight > carouselHeight.value) {
				carouselHeight.value = newHeight;
			}
		});

		setTimeout(() => {
			initialObserver.value = false;
		}, 500);
	});
	if (carouselSlideContentRef.value.length) {
		carouselSlideContentRef.value.forEach((element) => {
			const ctaComponents = element.children.namedItem("ctaComponents");
			if (ctaComponents) {
				resizeObserver.value.observe(ctaComponents);
			}
		});
	}
};

onMounted(() => {
	carouselHeight.value = window.innerWidth < mobileWidth ? 562 : 600;
	showNavigation.value = window.innerWidth > 1350;
	toggleFocusForClassLists(DISABLE_FOCUS_CLASSES, ENABLE_FOCUS_CLASSES);
	onNuxtReady(() => {
		observerHeight();
	});
});
onBeforeUnmount(() => {
	if (resizeObserver.value) {
		resizeObserver.value.disconnect();
	}
});
</script>
<style lang="scss" scoped>
$carouselMobileHeight: 562px;
$carouselDesktopHeight: 600px;
@mixin hero-banner-font-style {
	h1,
	h2,
	h3,
	p {
		color: $color-text;
	}
	h1 {
		@include font-title-display-2xl;
		@include breakpoint(mobile) {
			@include font-title-display-xl;
		}
	}
	p {
		@include font-text-l;
	}
}
.heroBannerCarouselContainer {
	color: $color-text;
	position: relative;
	.heroBannerCarousel {
		width: 100%;
		margin: 0 auto;
		.carouselSlideOuterContainer {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;

			.carouselSlideContainer {
				position: relative;
				width: 100%;
				display: flex;
				justify-content: center;
				max-width: 1920px;
				height: $carouselDesktopHeight;
				//components that are changing size during use should handle the transition themselves to prevent double height animations.
				transition: none;
				@include breakpoint(tablet) {
					height: $carouselMobileHeight;
				}
				&.initialObserver {
					transition: height 500ms; //transition only on initial load
				}
				.backgroundGraphicContainer {
					position: absolute;
					height: 100%;
					width: 100%;
				}

				.carouselSlideContent {
					z-index: 2;
					width: 100%;
					min-height: 100%;
					max-width: $size-screen-desktop;
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					&.onlyOneBannerElement {
						justify-content: flex-start;
					}
					@include breakpoint(mobile) {
						gap: 0;
						align-items: flex-start;
						flex-direction: column-reverse;
						justify-content: space-between;
						&.hasEmbeddedCtaComponents {
							flex-direction: column;
							justify-content: flex-end;
							.slideCta {
								padding-bottom: 0;
							}
							&.revert-direction-on-mobile {
								flex-direction: column-reverse;
								justify-content: space-between;
								.slideCta {
									padding-bottom: $spacing-m;
								}
							}
						}
						&.hasInlineGraphic {
							flex-direction: column-reverse;
							justify-content: space-between;
						}
					}

					.inlineGraphicContainer {
						max-width: 100%;
						padding: 0;
						flex: 0 1 auto;
						height: 100%;
						width: 100%;
						display: flex;
						@include breakpoint(mobile) {
							width: 100%;
							height: auto;
							flex-grow: 1;
							min-height: 80px;
						}
					}

					.slideCta:deep() {
						display: flex;
						flex-direction: column;
						width: 100%;
						max-width: 50%;
						height: 100%;
						padding: $spacing-m;
						padding-bottom: $spacing-l;
						justify-content: center;
						gap: $spacing-m;
						@include breakpoint(mobile) {
							height: auto;
							max-width: 100%;
							min-height: fit-content;
							justify-content: flex-end;
						}
						.slideCtaButtons {
							display: flex;
							flex-direction: row;
							flex-wrap: wrap;
							gap: $spacing-m;
							&.lightSecondaryOverride {
								.secondary {
									&.button {
										background-color: $color-information-50-tint;
									}
									&.button:hover {
										background-color: $color-information-200-tint;
									}
								}
								.link {
									color: $color-twe-text;
								}
							}
						}
						@include hero-banner-font-style;
						.ctaDescription {
							display: flex;
							flex-direction: column;
							gap: $spacing-m;
							span {
								width: fit-content;
							}
							&.isDarkModeText {
								h1,
								h2,
								h3,
								p {
									color: $color-twe-text;
								}
							}
						}
					}

					.slideCta {
						@include breakpoint(mobile) {
							&.onlyOneBannerElement {
								justify-content: flex-end;
							}
						}
					}

					.slideCtaComponentContainer {
						display: flex;
						flex-direction: column;
						width: 100%;
						max-width: 50%;
						padding: $spacing-m;
						padding-bottom: $spacing-l;
						justify-content: center;
						gap: $spacing-m;
						min-height: 100%;
						@include breakpoint(mobile) {
							max-width: 100%;
							min-height: fit-content;
							justify-content: flex-end;
							.slideCtaComponent {
								display: flex;
								flex-direction: column;
								&.onlyCtaComponent {
									justify-content: flex-end;
								}
							}
						}

						&.w70-no-padding-align-right {
							padding: 0;
							justify-content: flex-start;
							align-items: flex-end;
							margin-left: auto;
							.slideCtaComponent {
								width: 100%;
								max-width: 70%;
								@include breakpoint(mobile) {
									max-width: 100%;
								}
							}
						}

						.ctaComponentDescription {
							@include hero-banner-font-style;
							&.isDarkModeText {
								h1,
								h2,
								h3,
								p {
									color: $color-twe-text;
								}
							}
						}
					}
				}
			}
			&.dark-with-background-graphic {
				background-color: $color-neutrals-1000-shade;
			}
			&.dark-with-split-graphic {
				background-color: $color-twe-background;
			}
			&.lightblue-with-split-graphic {
				background-color: $color-primary-superlight;
			}
			.sideFadeLeft {
				position: absolute;
				height: 100%;
				width: 70%;
				background: linear-gradient(90deg, $color-neutrals-1000-shade 0%, rgba(240, 240, 240, 0) 100%);
				left: 0;
				z-index: 2;
			}
			.sideFadeRight {
				position: absolute;
				height: 100%;
				width: 30%;
				background: linear-gradient(90deg, rgba(240, 240, 240, 0) 0%, $color-neutrals-1000-shade 100%);
				right: 0;
				z-index: 2;
				@include breakpoint(mobile) {
					bottom: 0;
					background: linear-gradient(180deg, rgba(240, 240, 240, 0) 0%, $color-neutrals-1000-shade 100%);
					width: 100%;
					height: 70%;
				}
			}
		}
	}
}

.heroBannerCarousel:deep() {
	.carousel__next {
		@media (min-width: $size-screen-desktop) {
			margin-right: calc((100% - $size-screen-desktop) / 2 - $spacing-4xl);
		}
	}
	.carousel__prev {
		@media (min-width: $size-screen-desktop) {
			margin-left: calc((100% - $size-screen-desktop) / 2 - $spacing-4xl);
		}
	}
}
</style>
